/*

Common JS support for the app

*/

new SmartBanner({
  daysHidden: 15,
  daysReminder: 90,
  title: 'Pod Point Open Charge',
  author: 'Pod Point Ltd',
  button: 'VIEW',
  store: {
    ios: 'On the App Store',
    android: 'In Google Play',
    windows: 'In Windows store'
  },
  price: {
    ios: 'FREE',
    android: 'FREE',
    windows: 'FREE'
  },
});

/**
 * Sets a cookie.
 *
 * @param {string} key
 * @param {string} value
 * @param {int} days
 */
export function setCookie(key, value, days) {
  var date = new Date();
  date.setTime(date.getTime()+(days * 60000));
  var expires = "expires=" + date.toUTCString() + ";path=/";

  document.cookie = key + "=" + value + ";" + expires;
}

/**
 * Expires a cookie.
 *
 * @param {string} key
 */
export function expireCookie(key) {
  setCookie(key, '', -1);
}

/**
 * Expires existing cookie and create a new one.
 *
 * @param {string} key
 * @param {string} value
 * @param {int} days
 */
export function updateCookie(key, value, days) {
  expireCookie(key);

  setCookie(key, value, days);
}

/**
 * Makes a post request with csrfToken and cookies enabled.
 *
 * @param {string} url
 * @param {array} body
 * @param {string} csrfToken
 * @return Promise
 */
export function post(url, body, csrfToken) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
    credentials: 'same-origin',
    body: JSON.stringify(body),
  });
}

// Add global methods to the window via vite
if (globalThis) {
  globalThis.setCookie = setCookie;
  globalThis.expireCookie = expireCookie;
  globalThis.updateCookie = updateCookie;
  globalThis.post = post;
}
